var init_mec_index_page;

import * as echarts from 'echarts';

init_mec_index_page = function() {
    var chart, option, page_wrapper, wrapper;
    page_wrapper = document.querySelector('#page-wrapper.month_end_closing.index');
    if (page_wrapper) {
        if ($(page_wrapper).prop('initialized')) {
            return;
        }
        $(page_wrapper).prop('initialized', true);
        wrapper = document.getElementById('rating-factor-progress-chart');
        option = {
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                show: false
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: gon.mec_month_end_close_chart.xlabels
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: '{value}'
                },
                min: gon.mec_month_end_close_chart.min,
                max: gon.mec_month_end_close_chart.max
            },
            series: [
                {
                    name: gon.mec_month_end_close_chart.rating_label,
                    type: 'line',
                    lineStyle: {
                        color: '#E96F17'
                    },
                    itemStyle: {
                        color: '#E96F17'
                    },
                    data: gon.mec_month_end_close_chart.ratings,
                    markPoint: {
                        data: [
                            {
                                type: 'max',
                                name: 'Max'
                            }, {
                                type: 'min',
                                name: 'Min'
                            }
                        ],
                        itemStyle: {
                            color: '#e6e6e6'
                        }
                    },
                    markLine: {
                        data: [
                            {
                                name: 'Avg',
                                yAxis: gon.mec_month_end_close_chart.rating_factor_opening
                            }
                        ],
                        lineStyle: {
                            color: '#4b3627',
                            type: 'solid'
                        }
                    }
                }
            ]
        };
        chart = echarts.init(wrapper);
        chart.setOption(option);
        return $('.toggle-comment-button').on('click', function(event) {
            var button, cell, comment, down_button, up_button;
            cell = event.target.closest('td');
            button = $(cell).find('.toggle-comment-button');
            comment = $(cell).find('.mec-comment');
            down_button = $(cell).find('.fa-angle-down');
            up_button = $(cell).find('.fa-angle-up');
            if (down_button.hasClass('hide')) {
                down_button.removeClass('hide');
                up_button.addClass('hide');
                return comment.html(button.attr('data-comment-shortened'));
            } else {
                down_button.addClass('hide');
                up_button.removeClass('hide');
                return comment.html(button.attr('data-comment-entire'));
            }
        });
    }
};

$(function() {
    return init_mec_index_page();
});

$(document).on('turbolinks:load', function() {
    return init_mec_index_page();
});