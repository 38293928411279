import {Controller} from "@hotwired/stimulus"
import * as jquery from 'jquery'

export default class extends Controller {

    connect() {
        console.log('ADD STAFF CONTROLLER', this.element.innerHTML)
        const ary = JSON.parse(this.element.innerHTML)
        this.add(ary)
    }

    add(ary) {

        var reveal = jquery('#select-staff-reveal')
        reveal.foundation('close')

        var plus_button = document.getElementById('add-staff-member-button')

        ary.forEach(function (node) {
            jquery('.staff.nested-fields').removeClass('is-new')
            plus_button.click()
            var wrap = jquery('.staff.nested-fields.is-new').first()
            wrap.find('.user_id').val(node.id)
            wrap.find('.name').html(node.first_name + ' ' + node.last_name)
            wrap.find('.initials').html(node.initials)
        })
    }
}