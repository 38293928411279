import {Controller} from "@hotwired/stimulus"
import {computePosition, flip, autoPlacement, shift, offset, arrow} from '@floating-ui/dom';

// adds the class .floating-dropdown-panel
// triggers the dropdown-open event on the panel
// if data-src on panel is given: panel content is replaced by url-response, #arrow is placed back into panel and dropdown-open event is triggered as last
// if add_arrow is set to true => adds a arrow if this is not existing inside the panel
// if .close-dropdown is inside the panel, it adds a on-click-functionality which closes the panel

// for z-index issues https://github.com/floating-ui/floating-ui/issues/482
// and avoid having to write custom panels
// if data-toggle is set to true (= default!) (and no ID given here) the controller ...
//  • searches for element with #dropdown-panels-box
//  • if not found, places div#dropdown-panels-box inside the body tag
//  • creates %div.dropdown-panel-created-by-stimulus.hide.floating-dropdown-panel there
//  • the data-src attribute is taken from the button
//  • adds the data-panel-class (only one class) from the button to the panel


export default class extends Controller {

    add_arrow = true

    connect() {
        // add reference to this stimulus instance to the element for being able calling this methods from outside
        this.element[this.identifier + '_controller'] = this

        if (this.element.hasAttribute('data-hover')) {
            // not yet defined
        } else {

            // default: TOGGLE

            this.element.addEventListener('click', (ev) => this.toggle(ev))
            this.element.addEventListener('place-panel', () => this.place_panel())

            const target_id = this.element.getAttribute('data-toggle')
            if (!target_id || target_id === '' || target_id === 'true') {
                this.element.setAttribute('data-toggle', '')
            }

        }
    }

    toggle(e) {
        e.stopPropagation()
        const target_id = this.element.getAttribute('data-toggle')
        const panel = this.find_or_create_panel(target_id)
        if (panel.classList.contains('hide')) {
            this.open(e, panel)
        } else {
            this.close(panel)
        }
    }

    open(e, panel) {

        if (!window.dropdown_controller_close_all_on_click_outside_added) {
            window.dropdown_controller_close_all_on_click_outside_added = true;
            window.addEventListener('click', (e) => this.closeAllPanels(e))
        }

        this.closeAllPanels(e)

        panel.classList.remove('hide');
        const button = this.element


        if (panel.hasAttribute('data-set-focus')) {
            let data_focus = panel.getAttribute('data-set-focus')
            let focus_element = panel.querySelector(data_focus)
            focus_element.focus()
        }

        // arrow
        const found_arrow = panel.querySelector('#arrow')
        const new_arrow = document.createElement('div')
        new_arrow.setAttribute('id', 'arrow')
        const add_arrow = this.add_arrow
        if (!found_arrow && add_arrow) {
            panel.appendChild(new_arrow)
        }

        // .close-dropdown
        const add_close_button_function = function (panel) {
            const close_buttons = panel.querySelectorAll('.close-dropdown')
            for (const btn of close_buttons) {
                btn.addEventListener('click', () => panel.classList.add('hide'))
            }
        }

        // open events

        const open_event = new CustomEvent('dropdown-open')
        const place_panel_event = new CustomEvent('place-panel')
        if (panel.hasAttribute('data-src')) {
            this.place_panel(panel)
            let xhr = new XMLHttpRequest();
            xhr.open('GET', panel.getAttribute('data-src'));
            xhr.send();
            xhr.onload = function () {
                if (xhr.status !== 200) {
                    alert(`Dropdown Controller, GET «${url}», Error ${xhr.status}: ${xhr.statusText}`);
                } else {
                    panel.innerHTML = xhr.response
                    if (found_arrow) {
                        panel.appendChild(found_arrow)
                    } else if (add_arrow) {
                        panel.appendChild(new_arrow)
                    }
                    panel.dispatchEvent(open_event)
                    button.dispatchEvent(place_panel_event)
                    add_close_button_function(panel)
                }
            }

        } else {
            this.place_panel(panel)
            panel.dispatchEvent(open_event)
            add_close_button_function(panel)
        }


    }

    close(panel) {
        panel.classList.add('hide')
    }

    closeAllPanels(ev) {

        // climb up stack to parent panels

        let btn = ev.target
        let parent_panel_ids = []

        while (true) {

            const parent_panel = btn.closest('.floating-dropdown-panel')
            if (parent_panel) {
                parent_panel_ids.push(parent_panel.id)

                btn = document.querySelector(`[data-toggle="${parent_panel.id}"]`)
                //console.log('INSIDE', parent_panel_ids)

            } else {
                break
            }
        }

        // close all panels except parents

        const panels = document.getElementsByClassName('floating-dropdown-panel')
        for (const panel of panels) {
            if (!parent_panel_ids.includes(panel.id)) {
                panel.classList.add('hide')
            }
        }
    }

    place_panel() {

        const t_id = this.element.getAttribute('data-toggle')
        let panel = document.getElementById(t_id)
        let arrow_element = panel.querySelector('#arrow')

        computePosition(this.element, panel, {
            middleware: [flip(), offset(6), shift({padding: 5}), arrow({element: arrow_element})]
        }).then(({x, y, placement, middlewareData}) => {

            Object.assign(panel.style, {
                left: `${x}px`,
                top: `${y}px`,
            });

            if (arrow_element) {

                //ARROW
                const {x: arrowX, y: arrowY} = middlewareData.arrow;
                const staticSide = {
                    top: 'bottom',
                    right: 'left',
                    bottom: 'top',
                    left: 'right',
                }[placement.split('-')[0]];
                Object.assign(arrow_element.style, {
                    left: arrowX != null ? `${arrowX}px` : '',
                    top: arrowY != null ? `${arrowY}px` : '',
                    right: '',
                    bottom: '',
                    [staticSide]: '-4px',
                });
            }

            this.constrain_to_window_borders(panel)
        });
    }

    constrain_to_window_borders(panel) {
        if (this.element.getAttribute('data-constrain-to-window-borders') === 'true') {

            const rect = panel.getBoundingClientRect();

            if (rect.top < 0) {
                const new_top = parseFloat(panel.style.top) + Math.abs(rect.top) + 2
                console.log('constrain top', 'actual top:', panel.style.top, 'absolute top:', rect.top, 'new top:', new_top)
                panel.style.top = `${new_top}px`
            }

            const rect2 = panel.getBoundingClientRect();

            if (rect2.bottom > window.innerHeight) {
                console.log('constrain height')
                const h = window.innerHeight - rect2.top - 2
                panel.style.height = `${h}px`
            }


        }
    }

    find_or_create_panel(panel_id) {
        if (panel_id === '' || !panel_id) {

            // find or create the wrapper

            let wrapper = document.getElementById('dropdown-panels-box')
            const e = document.createElement('div')
            e.id = 'dropdown-panels-box'
            if (!wrapper) {
                const b = document.querySelector('body')
                b.appendChild(e)
                wrapper = e
            }

            // find or create the panel

            const hex = '0123456789ABCDEF';
            let rand_hex = '';
            for (let i = 0; i < hex.length; ++i) {
                rand_hex += hex.charAt(Math.floor(Math.random() * hex.length));
            }

            const panel = document.createElement('div')
            panel.id = rand_hex;
            panel.className = 'dropdown-panel-created-by-stimulus hide'
            this.element.setAttribute('data-toggle', rand_hex)
            panel.setAttribute('data-src', this.element.getAttribute('data-src'))
            this.element.removeAttribute('data-src')
            panel.classList.add(this.element.getAttribute('data-panel-class'))
            this.element.removeAttribute('data-panel-class')
            panel.classList.add('floating-dropdown-panel')
            wrapper.appendChild(panel)

            return panel

        } else {

            const panel = document.getElementById(panel_id)
            panel.classList.add('floating-dropdown-panel')
            return panel

        }
    }

}