import {Controller} from "@hotwired/stimulus"
import $ from "jquery";

export default class extends Controller {

    connect() {
        if (this.element.getAttribute('data-active') === '1') {
            window.setTimeout(() => this.set_active(), 10)
        }
        this.element.addEventListener('click', (e) => {
            const body = document.querySelector('body')
            if (body.classList.contains('full-screen-mode-active')) {
                body.classList.remove('full-screen-mode-active')
            } else {
                body.classList.add('full-screen-mode-active')
                if (!this.element.hasAttribute('data-original-label')) {
                    this.element.setAttribute('data-original-label', this.element.innerHTML)
                }
            }
            this.set_button_label()
            this.set_form_height_timer()
        })
        this.set_form_height_timer()
        this.set_button_label()
    }

    set_form_height_timer() {
        const main = document.querySelector('div.main-container.projects-edit-workload-view')
        const rand = Math.random() * 100000
        main.setAttribute('data-process-id', rand)
        //console.log('start timer', rand)
        window.setTimeout(() => this.set_form_height(rand), 100)
    }

    set_form_height(process_id) {
        //console.log('WINDOW  height', window.innerHeight)

        const main = document.querySelector('div.main-container.projects-edit-workload-view')
        const main_rect = main.getBoundingClientRect();
        const p_id = parseFloat(main.getAttribute('data-process-id'))
        if (p_id !== process_id) {
            //console.log(p_id, process_id)
            return true
        }

        const page_wrapper = document.getElementById('page-wrapper')
        var page_rect = page_wrapper.getBoundingClientRect();
        //console.log('PAGE WRAPPER top', page_rect.top, 'bottom', page_rect.bottom, 'height', page_wrapper.offsetHeight)

        const scrollable_element = document.getElementById('cocoon-workload-container')
        const scrollable_rect = scrollable_element.getBoundingClientRect()
        //console.log('SCROLLABLE top', scrollable_rect.top, 'bottom', scrollable_rect.bottom, 'height', scrollable_element.offsetHeight)


        const body = document.querySelector('body')
        if (body.classList.contains('full-screen-mode-active')) {

            // FULL SCREEN MODE
            const form_wrapper = document.querySelector('.form-inputs')
            const form_rect = form_wrapper.getBoundingClientRect()
            //console.log('FORM top', form_rect.top, 'bottom', form_rect.bottom, 'height', form_rect.offsetHeight)
            const add = window.innerHeight - form_rect.bottom
            const new_height = scrollable_element.offsetHeight + add - 25
            //console.log('FULL SCREEN VIEW => ACTION => add', add, 'previous height', scrollable_element.offsetHeight, 'new height', new_height)
            scrollable_element.style.maxHeight = new_height + 'px'
            window.addEventListener('resize', () => this.set_form_height_timer())

        } else {

            // NORMAL VIEW
            const add = window.innerHeight - page_rect.bottom
            const new_height = scrollable_element.offsetHeight + add - 25
            //console.log('NORMAL VIEW => ACTION => add', add, 'previous height', scrollable_element.offsetHeight, 'new height', new_height)
            scrollable_element.style.maxHeight = new_height + 'px'
            window.removeEventListener('resize', () => this.set_form_height_timer())

        }
        return true
    }

    set_button_label() {
        const body = document.querySelector('body')
        const mode_input = document.querySelector('input#project_full_screen_mode')
        if (!this.element.hasAttribute('data-original-label')) {
            this.element.setAttribute('data-original-label', this.element.innerHTML)
        }
        if (body.classList.contains('full-screen-mode-active')) {
            this.element.innerHTML = this.element.getAttribute('data-alternate-label')
            mode_input.value = 1
        } else {
            mode_input.value = 0
            this.element.innerHTML = this.element.getAttribute('data-original-label')
        }

        const sticky_bug = document.getElementById('sticky-with-bugfix')
        const st = window.getComputedStyle(sticky_bug)
        if (st['max-width'] === '0px') {
            sticky_bug.style.maxWidth = null
        }

    }

    set_active() {
        document.querySelector('body').classList.add('full-screen-mode-active')
        this.set_button_label()
        this.set_form_height_timer()
    }
}